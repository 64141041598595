<template>
  <div v-if="data?.list">
    <UITableResponsive :items="data.list || []" class="text-center w-auto" :headers="headers">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-32" />
        </colgroup>
      </template>
      <template v-slot:item-Status="{ item }">
        <span class="font-bold" :class="formatDocStatus(item.amount_open, item.expiration_date).class">
          {{ formatDocStatus(item.amount_open, item.expiration_date).label }}
        </span>
      </template>
      <template v-slot:item-Document="{ item }">
        <div>{{ formatDocNumber(item.doc_number, item.journal_id) }}</div>
      </template>
      <template v-slot:item-Datum="{ item }">
        <span>{{ dateStringLocale(item.date) }}</span>
        <UILabelFactuurEmailVerstuurd class="block -mb-2" :sjabloon="item.laatst_verzonden" :log="item.laatst_verzonden_mail" />
      </template>
      <template v-slot:item-Vervaldag="{ item }">
        <span>{{ dateStringLocale(item.expiration_date) }}</span>
      </template>
      <template v-slot:item-Klant="{ item }">
        <span>{{ `${item.name || ''} ${item.second_name || ''}`.trim() }}</span>
        <small class="block text-gray-500 -mt-1">{{ item.BTW }}</small>
      </template>
      <template v-slot:item-Bedragen="{ item }">
        <span>{{ formatEuro(item.price_incl) }}</span>
        <small class="block text-gray-500 -mt-1">
          Open: {{ formatEuro(item.amount_open) }}
        </small>
      </template>
      <template v-slot:item-Acties="{ item }">
        <div class="flex flex-row items-start gap-2">
          <button class="w-10 h-10 bg-gray-200 hover:bg-gray-300 border border-gray-300 text-center flex flex-col gap-1 items-center justify-center rounded" @click="handleGenereer(item)">
            <i class="fas fa-print"></i>
            <small style="font-size: 50%">Genereer</small>
          </button>
          <button v-if="store.getters.hasKantoorPermission" class="w-10 h-10 bg-teal-200 hover:bg-teal-300 border border-teal-300 text-center flex flex-col gap-1 items-center justify-center rounded" @click="handleMailen(item)">
            <i class="fas fa-envelope"></i>
            <small style="font-size: 50%">Mail</small>
          </button>
          <button
            v-if="store.getters.hasKantoorPermission && isInvoice"
            :disabled="item.amount_open <= 0"
            :class="{
              'bg-green-300 hover:bg-green-400 border-green-400': item.amount_open > 0,
              'line-through cursor-not-allowed': item.amount_open <= 0,
            }"
            class="w-10 h-10 border text-center flex flex-col gap-1 items-center justify-center rounded"
            @click="handlePayment(item)"
          >
            <i class="fas" :class="item.amount_open > 0 ? 'fa-euro-sign' : 'fa-check'"></i>
            <small style="font-size: 50%">Betaal</small>
          </button>
          <button v-if="store.getters.hasKantoorPermission" class="w-10 h-10 bg-yellow-200 hover:bg-yellow-300 border border-yellow-300 text-center flex flex-col gap-1 items-center justify-center rounded" @click="handleBewerken(item)">
            <i class="fas fa-edit"></i>
            <small style="font-size: 50%">Bewerk</small>
          </button>
          <button v-if="store.getters.hasKantoorPermission && isInvoice" class="w-10 h-10 bg-blue-200 hover:bg-blue-300 border border-blue-300 text-center flex flex-col gap-1 items-center justify-center rounded" @click="handleCreditNote(item)">
            <i class="fas fa-hand-holding-usd"></i>
            <small style="font-size: 50%">Credit nota</small>
          </button>
          <button v-if="store.getters.hasKantoorPermission" class="w-10 h-10 bg-red-200 hover:bg-red-300 border border-red-300 text-center flex flex-col gap-1 items-center justify-center rounded" @click="handleVerwijderen(item)">
            <i class="fas fa-trash"></i>
            <small style="font-size: 50%">Verwijder</small>
          </button>
          <button v-if="store.getters.hasKantoorPermission && isInvoice" class="w-10 h-10 bg-purple-200 hover:bg-purple-300 border border-purple-300 text-center flex flex-col gap-1 items-center justify-center rounded" @click="handleVouchers(item)">
            <i class="fas fa-gift"></i>
            <small style="font-size: 50%">Vouchers</small>
          </button>
        </div>
      </template>
      <template v-slot:item-Bedrijf_Thema="{ item }">
        <UISelectFixed v-if="store.getters.hasKantoorPermission" type="BEDRIJVEN_THEMA" label="" class="w-40 max-w-sm" :modelValue="item.bedrijf_thema" @update:model-value="handleUpdateThema(item, $event)"/>
        <span v-else class="block pr-4 font-bold">{{item.bedrijf_thema}}</span>
      </template>
    </UITableResponsive>
    <UITablePagination class="mt-2" :count="data?.count || 0" v-model:limit="limit" v-model:offset="offset" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { dateStringLocale } from '@/functions/formatDate'
import { formatEuro } from '@/functions/formatNumber'
import { formatDocNumber, formatDocStatus } from '@/functions/formatText'

import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'
import useModal from '@/hooks/useModal'
import useRedirectHref from '@/hooks/useRedirectHref'
import useRedirectSafe from '@/hooks/useRedirectSafe'

import UITableResponsive from '@/components/UI/Table/Responsive'
import UITablePagination from '@/components/UI/Table/Pagination'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UILabelFactuurEmailVerstuurd from '@/components/UI/Labels/FactuurEmailVerstuurd.vue'

const router = useRouter()
const store = useStore()

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: 'INVOICE',
  },
  typeLabel: {
    type: String,
    default: 'Factuur',
  },
})

// const store = useStore()

const isInvoice = computed(() => props.type === 'INVOICE')
const headers = ['Status', 'Document', 'Datum', 'Vervaldag', 'Klant', 'Bedragen', 'Acties', 'Bedrijf_Thema']
const routeType = {
  INVOICE: 'facturen',
  CREDIT_NOTE: 'creditnotas',
}

const [openFixedModal] = useModal({ fixed: true })
const api = useApi()

const limit = ref(10)
const offset = ref(0)
const apiData = computed(() => ({ limit: limit.value, offset: offset.value, ...props.filters }))

const { data, getData } = useGetApi('FINANCIAL_DOCS_LIST', apiData, { watch: true })

const handleBewerken = (item) => {
  useRedirectSafe(`/facturatie/${routeType[item.type]}/bewerken/${item.doc_number}?journal_id=${item.journal_id}`)
}
const handleGenereer = (item) => {
  let link = `/api/shared/files/financial/${item.type}/${item.journal_id}-${new Date(item.creation_datetime).getTime()}-${item.doc_number}`
  if (store.getters.hasBoekhouderPermission) {
    link += '?print=true'
  }
  useRedirectHref(link)
}
const handleVerwijderen = (item) => {
  const message = `${props.typeLabel} ${formatDocNumber(item.doc_number, item.journal_id)} verwijderen?`
  const subtext = 'Deze actie is onomkeerbaar!'
  const callback = () => {
    api('FINANCIAL_DOCS_DELETE', { journal_id: item.journal_id, type: item.type, doc_numbers: [item.doc_number] }).then(getData)
  }
  openFixedModal('BEVESTIG_ACTIE', { message, subtext, callback })
}

const handleCreditNote = (item) => {
  const bedrag = Number(item.price_incl - item.amount_open)
  router.push(`/facturatie/creditnotas/nieuw/${item.doc_number}?bedrag=${bedrag}&totaal_prijs=${item.price_incl}&journal_id=${item.journal_id}`)
}

const handleUpdateThema = (item, bedrijf_thema) => {
  api('FINANCIAL_DOCS_THEMA', { journal_id: item.journal_id, type: item.type, doc_number: item.doc_number, bedrijf_thema }).then(() => {
    item.bedrijf_thema = bedrijf_thema
  })
}

const handleMailen = (item) => {
  openFixedModal('FACTUUR_MAILEN', { modelValue: item }, { fullWidth: true })
}

const handleVouchers = (item) => {
  api('FINANCIAL_DOCS_STATS_VOUCHERS', { journal_id: item.journal_id, type: item.type, doc_number: item.doc_number })
}

const handlePayment = (item) => {
  const betaling = {
    type: item.type,
    doc_number: item.doc_number,
    journal_id: item.journal_id,
    date: new Date(),
    bedrag: item.amount_open,
    methode: 'OVERSCHRIJVING',
  }
  openFixedModal('FORM_BETALING_FINANCIAL', {
    modelValue: betaling,
    callback: () => {
      getData()
    },
  })
}
</script>

<style scoped lang="scss">
  :v-deep(table td),
  :v-deep(table th) {
    padding: 8px 16px;
    &:first-child {
      padding-left: 10px;
    }
    &:last-child {
      padding-right: 10px;
    }
  }
</style>
